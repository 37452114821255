
// Libraries
import { Component, Prop, Vue } from "nuxt-property-decorator";

// Components
import RouterLink from "~/components/atoms/text/RouterLink.vue";

@Component({
  components: {
    RouterLink,
  },
})
export default class PageJumpNavigationMolecule extends Vue {
  @Prop({ default: "default" }) variant: string;
  @Prop() headline: string;
  @Prop() scrollDistance: string;
  @Prop({ required: true }) menuItems: [
    { name: string; title: string; href: { path: string; hash: string } }
  ];

  scrollToSection(section: string): void {
    const targetElement = document.querySelector(section);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  }
}
